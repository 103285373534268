import React from "react";
import Banner from "../../components/Banner/Banner";
import "./About.scss";
import BannerImg from "../../assets/banner3.jpeg"; // Ensure the path and extension are correct
import MeetUs from "./MeetUs/MeetUs";
import Perks from "./Perks/Perks";
import Values from "./Values/Values";

function About() {
  return (
    <div className="about-page">
      <Banner img={BannerImg} title={""} />
      <div className="about-content">
        <h2>About Us</h2>
        <p>
          Welcome to Himalaya Fair Reisen, where we strive to deliver the best
          experience. Our mission is to provide high-quality, personalized
          services tailored to each client's unique needs. We are dedicated to
          ensuring you receive the utmost care and professionalism through our
          offerings.
        </p>
        <p>
          Established in 1999 A.D., our family-run company has grown from a
          small startup to a leading player in the tourism industry. Based in
          the beautiful country of Nepal, we specialize in creating home-like
          travel packages that offer exceptional value for money. Our commitment
          to innovation and excellence drives us to continuously expand our
          services and enhance our impact. With competitive pricing and a
          hardworking team, we always aim to provide the best hospitality,
          making your travel experience with us unforgettable.
        </p>
      </div>
      <MeetUs />
      <Perks />
      <Values />
    </div>
  );
}

export default About;
