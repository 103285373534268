import React, { useState, useEffect } from "react";
import "./Gallery.scss";
import axios from "axios";
import Banner from "../../../components/Banner/Banner";
import safari from "../../../assets/safari.jpg";

const API_URL = "https://backend.himalayafairreisen.com";

const Gallery = () => {
  const [activeTab, setActiveTab] = useState("albums");
  const [isFullImgOpen, setIsFullImgOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [albums, setAlbums] = useState([]);
  const [currentAlbumPhotos, setCurrentAlbumPhotos] = useState([]);

  useEffect(() => {
    // Simulate loading effect
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Reduced loading time

    fetchAlbums();

    return () => clearTimeout(timer);
  }, []);

  // Fetch albums from the backend
  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`${API_URL}/albums`);
      setAlbums(response.data);
      console.log("Fetched albums:", response.data);
    } catch (error) {
      console.error("Error fetching albums:", error.message);
      alert("Failed to fetch albums. Please try again.");
    }
  };

  // Fetch photos for a specific album when an album is clicked
  const fetchPhotos = async (albumId) => {
    try {
      const response = await axios.get(`${API_URL}/photos`, {
        params: { album_id: albumId },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching photos:", error.message);
      return [];
    }
  };

  // Open an album and fetch the photos for that album
  const handleOpenAlbum = async (albumId) => {
    setIsImageLoading(true); // Start image loading spinner
    const photos = await fetchPhotos(albumId); // Fetch photos for this album
    setCurrentAlbumPhotos(photos); // Set the fetched photos
    setActiveTab(`album-${albumId}`); // Switch to album view
    setIsImageLoading(false); // Stop the loading spinner
  };

  // Open full image
  const openFullImg = (pic) => {
    setCurrentImg(pic);
    setIsFullImgOpen(true);
  };

  const closeFullImg = () => {
    setIsFullImgOpen(false);
    setCurrentImg("");
  };

  // Return to albums view
  const handleBackToAlbums = () => {
    setCurrentAlbumPhotos([]); // Clear current photos
    setActiveTab("albums"); // Switch back to album view
  };

  // Render the list of albums
  const renderAlbums = () => {
    return (
      <div className="album-gallery">
        {albums.map((album) => (
          <div key={album.id} className="album">
            <img
              src={`${API_URL}${album.cover_image}`} // Ensure this matches your backend cover image route
              alt={album.name || "Album Cover"}
              className="album-cover"
              onClick={() => handleOpenAlbum(album.id)} // Open album to view photos
            />
            <div className="album-info">
              <h3>{album.name || "Untitled Album"}</h3>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Render the list of images in a selected album
  const renderImages = () => {
    return (
      <div>
        {isImageLoading ? ( // Display loader if images are loading
          <div className="cssloader">
            <div className="triangle1"></div>
            <div className="triangle2"></div>
            <p className="text">Please Wait</p>
          </div>
        ) : (
          <div className="image-gallery">
            {currentAlbumPhotos.length > 0 ? (
              currentAlbumPhotos.map((photo) => (
                <img
                  key={photo.id}
                  src={`${API_URL}${photo.image_path}`} // Ensure this matches the photo route
                  onClick={() => openFullImg(`${API_URL}${photo.image_path}`)}
                  alt={photo.title || "Image"} // Use photo title as alt text
                  className="gallery-image"
                />
              ))
            ) : (
              <p>No images found in this album.</p>
            )}
          </div>
        )}
        <button className="back-albm" onClick={handleBackToAlbums}>
          Back to Albums
        </button>
      </div>
    );
  };

  // Determine what content to display (albums or images)
  const renderContent = () => {
    if (activeTab === "albums") {
      return renderAlbums();
    } else if (activeTab.startsWith("album-")) {
      return renderImages();
    }
  };

  return (
    <div className="gallery-section">
      <Banner img={safari} title="Gallery" />

      <div className="tabs">
        <button
          className={`tab ${activeTab === "albums" ? "active" : ""}`}
          onClick={handleBackToAlbums}
          style={{ opacity: "0", visibility: "hidden" }}
        >
          Images
        </button>
      </div>

      <div className="content">
        {isLoading ? (
          <div className="cssloader">
            <div className="triangle1"></div>
            <div className="triangle2"></div>
            <p className="text">Please Wait</p>
          </div>
        ) : (
          renderContent()
        )}
      </div>

      {isFullImgOpen && (
        <div className="full-img" id="fullImgBox">
          <img src={currentImg} id="fullImg" alt="Selected" />
          <span onClick={closeFullImg} className="close-btn">
            X
          </span>
        </div>
      )}
    </div>
  );
};

export default Gallery;
