import React from "react";
import "./Whatsapp.scss";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

const WhatsApp = () => {
  return (
    <div className="WhatsApp">
      <WhatsAppWidget
        phoneNumber="9707493626" // Add your phone number here
        companyName="Himalaya Fair Reisen"
        message="Hello, how can we help you?"
      />
    </div>
  );
};

export default WhatsApp;
