import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Banner from "../../../components/Banner/Banner";
import TrekRoutes from "../../../components/TrekRoutes/TrekRoutes";
import axios from "axios";
import "./RouteDetails.scss";
import nepalImage from "../../../assets/NepalBanner.jpg";

function RouteDetails() {
  const [destinations, setDestinations] = useState([]);
  const [routeDescription, setRouteDescription] = useState("");
  const [routeImage, setRouteImage] = useState(nepalImage);
  const { routeTitle } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDestinations();
    fetchRouteDescription();
  }, [routeTitle]);

  const fetchDestinations = async () => {
    try {
      const response = await axios.get(
        "https://backend.himalayafairreisen.com/destinations"
      );

      const filteredDestinations = response.data.filter(
        (destination) => destination.routes === routeTitle
      );
      setDestinations(filteredDestinations);
      console.log(filteredDestinations, "This is the data");
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };

  const fetchRouteDescription = async () => {
    try {
      const response = await axios.get(
        `https://backend.himalayafairreisen.com/trekroutes`
      );

      const route = response.data.find((route) => route.title === routeTitle);
      if (route) {
        setRouteDescription(route.description);
      }
    } catch (error) {
      console.error("Error fetching route description:", error);
    }
  };

  const handleViewDetails = (id) => {
    navigate(`/destination/${id}`);
  };

  return (
    <div className="route-details-container">
      <Banner img={nepalImage} title={routeTitle} />
      {routeDescription && (
        <div className="route-description">
          <p className="description">{routeDescription}</p>
        </div>
      )}
      <hr
        style={{
          width: "80%",
          height: "6px",
          borderRadius: "10px",
          border: "none",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
          marginBottom: "80px",
          marginTop: "90px",
        }}
      />
      <div className="info">
        {destinations.map((destination) => (
          <TrekRoutes
            key={destination.id}
            image={`https://backend.himalayafairreisen.com/destinations/${destination.id}/image`}
            trekName={destination.title}
            days={destination.days}
            altitude={destination.maximum_altitude}
            price={destination.price}
            onViewDetails={() => handleViewDetails(destination.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default RouteDetails;
