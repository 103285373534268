import React from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import "./Card.scss";

function Card({ image, name, heading, description }) {
  return (
    <div className="card">
      <img src={image} alt={name} className="card-image" />
      <div className="card-content">
        <div className="content1">
          <h3 className="country-name">{name}</h3>
          <h4 className="heading">{heading}</h4>
          <p className="description">{description}</p>
          <Link to={`/destination/${name}`} className="learn-more">
            Explore
          </Link>
        </div>
        <div class="bg-design">
          <div class="circle"></div>
          <div class="circle" id="right"></div>
          <div class="circle" id="bottom"></div>
        </div>
      </div>
    </div>
  );
}

export default Card;
