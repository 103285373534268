import React from "react";
import "./Home.scss";
import Banner from "./Banner/Banner";
import About from "./About/About";
import Trips from "./Trips/Trips";
import Newsletter from "./Newsletter/Newsletter";
import Destination from "./Destination/Destination";
import TrustPilot from "./TrustPilot/TrustPilot";
import TripAdvisorWidget from "./Reviews/Review";

function Home() {
  return (
    <div>
      <Banner />
      <div className="home">
        <About />
        <Trips />
        <Destination />
      </div>

      <Newsletter />
      <TrustPilot />
      <TripAdvisorWidget />
    </div>
  );
}

export default Home;
