import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DetailLayout from "../../components/DetailLayout/DetailLayout";

function DestinationDetail() {
  const { id } = useParams();
  const [destination, setDestination] = useState(null);

  useEffect(() => {
    fetchDestination();
  }, [id]);

  const fetchDestination = async () => {
    try {
      const response = await axios.get(
        `https://backend.himalayafairreisen.com/travel/${id}`
      );
      const destinationData = response.data;
      // Parse itinerary into array of objects
      destinationData.itinerary = parseItinerary(destinationData.itinerary);
      destinationData.bannerImg = `https://backend.himalayafairreisen.com/travel/${id}/image`;
      setDestination(destinationData);
    } catch (error) {
      console.error("Error fetching destination:", error);
    }
  };

  const parseItinerary = (itineraryText) => {
    const days = itineraryText.split(/Day \d+:/).filter((day) => day.trim());
    return days.map((day, index) => {
      const [title, ...content] = day.trim().split("\n");
      return {
        day: `Day ${index + 1}`,
        title: title.trim(),
        content: content.join("\n").trim(),
      };
    });
  };

  if (!destination) {
    return (
      <div className="loader" style={{ marginTop: "150px" }}>
        <div className="cssloader">
          <div className="triangle1"></div>
          <div className="triangle2"></div>
          <p className="text">Please Wait</p>
        </div>
      </div>
    );
  }

  return (
    <DetailLayout
      id={id}
      title={destination.title}
      bannerImg={destination.bannerImg}
      description={destination.description}
      itinerary={destination.itinerary}
      map={destination.map}
      included={destination.included}
      notIncluded={destination.not_included}
    />
  );
}

export default DestinationDetail;
