import React, { useState, useEffect } from "react";
import banner2 from "../../../assets/banner.jpeg";
import banner1 from "../../../assets/mountain.png";
import banner3 from "../../../assets/banner3.jpeg";
import test from "../../../assets/hero-new.jpg";
import banner4 from "../../../assets/banner4.png";
import "./Banner.scss";

function Banner() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isBlur, setIsBlur] = useState(false);

  const dummyData = [
    {
      id: 1,
      url: banner1,
      alt: "Image 1",
      quote: "Journey to the Heart of Adventure",
      description:
        "Join Himalaya Fair Reisen on a journey to the heart of adventure. Our tours are designed for those who crave excitement and discovery. From thrilling outdoor activities to immersive cultural experiences, we offer adventures that will leave you exhilarated and inspired.",
    },
    {
      id: 2,
      url: banner2,
      alt: "Image 2",
      quote: "Where Every Trip is a Story",
      description:
        "Embark on an adventure with Himalaya Fair Reisen, where excitement and discovery await. Our tours cater to thrill-seekers and explorers, offering everything from exhilarating outdoor activities to deep cultural immersions. Experience adventures that will invigorate and inspire you.",
    },
    {
      id: 3,
      url: test,
      alt: "Image 3",
      quote: "Discover Untouched Trails",
      description:
        "Discover untouched trails with Himalaya Fair Reisen. Our treks take you off the beaten path, revealing hidden gems and pristine environments that few have experienced.",
    },
    {
      id: 4,
      url: banner4,
      alt: "Image 3",
      quote: "Creating Journeys & Crafting Stories",
      description:
        "At Himalaya Fair Reisen, we create journeys that craft lifelong stories. Each of our tours is designed to provide meaningful experiences that you will cherish forever. Join us and start crafting your travel story today.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlur(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === dummyData.length - 1 ? 0 : prevIndex + 1
        );
        setIsBlur(false);
      }, 500); // Duration for blur effect
    }, 7000); // Increased interval duration to 7000ms (7 seconds)

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setIsBlur(true);
    setTimeout(() => {
      setCurrentIndex(
        currentIndex === 0 ? dummyData.length - 1 : currentIndex - 1
      );
      setIsBlur(false);
    }, 500);
  };

  const goToNext = () => {
    setIsBlur(true);
    setTimeout(() => {
      setCurrentIndex(
        currentIndex === dummyData.length - 1 ? 0 : currentIndex + 1
      );
      setIsBlur(false);
    }, 500);
  };

  const navigateToBooking = () => {};

  return (
    <div
      className="banner-container"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <img
        src={dummyData[currentIndex].url}
        alt={dummyData[currentIndex].alt}
        className={`banner-image ${isBlur ? "blur" : ""}`}
      />

      <div className={`text-overlay ${isMouseOver ? "visible" : ""}`}>
        <h1>{dummyData[currentIndex].quote}</h1>
        <p className="description">{dummyData[currentIndex].description}</p>
        <button style={{ background: "none", border: "none" }}>
          <a
            href="https://www.himalayan-friends.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="learn-more"
            style={{ fontSize: "16px", textDecoration: "none" }}
          >
            Visit Our German Site &rarr;
          </a>
        </button>
      </div>
      <button className="nav-button left" onClick={goToPrevious}>
        &#10094;
      </button>
      <button className="nav-button right" onClick={goToNext}>
        &#10095;
      </button>
    </div>
  );
}

export default Banner;
