import React from "react";
import Banner from "../../components/Banner/Banner";
import "./Destination.scss";
import BannerImg from "../../assets/banner3.jpeg";
import Nepal from "../../assets/DestinationNepal.jpg"; // Make sure the path and extension are correct
import India from "../../assets/DestinationIndia.avif"; // Make sure the path and extension are correct
import Bhutan from "../../assets/DestinationBhutan.avif"; // Make sure the path and extension are correct
import Tibet from "../../assets/DestinationTibet.jpeg"; // Make sure the path and extension are correct
import Card from "./Card/Card";

function Destination() {
  const countries = [
    {
      name: "Nepal",
      heading: "Explore the Himalayas",
      description:
        "Nepal, nestled in the majestic Himalayas, is renowned for its stunning mountain landscapes, including the iconic Mount Everest. The country's warm-hearted and hospitable people enrich its vibrant culture. Nepal offers unique experiences, from colorful festivals and ancient temples to exquisite handicrafts and delicious cuisine, making it a captivating destination for travelers.",
      image: Nepal, // Assuming you are using the same image for demonstration
    },
    {
      name: "India",
      heading: "Discover Diversity",
      description:
        "India, a land of diverse cultures and traditions, enchants visitors with its rich history and vibrant heritage. From the serene beauty of the Himalayas to the golden sands of Rajasthan, and from mouth-watering cuisine to colorful festivals, India's warmth and hospitality create an unforgettable experience for all who explore its wonders.",
      image: India, // Replace with actual path if different
    },
    {
      name: "Tibet",
      heading: "Mystical Mountains",
      description:
        "Tibet, known as the 'Roof of the World' captivates with its stunning Himalayan landscapes and spiritual serenity. Home to ancient monasteries and the sacred Potala Palace, Tibet's rich culture and warm-hearted people offer a unique, tranquil experience. Its breathtaking scenery and profound spirituality make Tibet a truly enchanting destination.",
      image: Tibet, // Replace with actual path if different
    },
    {
      name: "Bhutan",
      heading: "The Last Shangri-La",
      description:
        "Bhutan, the 'Land of the Thunder Dragon', enchants with its pristine Himalayan landscapes and rich cultural heritage. Known for its emphasis on Gross National Happiness, Bhutan offers serene monasteries, vibrant festivals, and warm-hearted people. This tranquil kingdom promises a unique, enriching experience, blending natural beauty with deep spiritual traditions.",
      image: Bhutan, // Replace with actual path if different
    },
  ];

  return (
    <div>
      <Banner img={BannerImg} title="Destination" />
      <div className="destination-country">
        {countries.map((country) => (
          <Card
            key={country.name}
            image={country.image}
            name={country.name}
            heading={country.heading}
            description={country.description}
          />
        ))}
      </div>
    </div>
  );
}

export default Destination;
