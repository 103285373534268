// import React, { useEffect, useState } from "react";

// export const Review = () => {
//   const [htmlContent, setHtmlContent] = useState("");

//   useEffect(() => {
//     fetch("/index.html")
//       .then((response) => response.text())
//       .then((data) => {
//         setHtmlContent(data);
//       })
//       .catch((error) => {
//         console.error("Error fetching the HTML file:", error);
//       });
//   }, []);

//   return (
//     <div>
//       <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
//     </div>
//   );
// };

import React, { useEffect } from "react";
import "./Review.scss";

const TripAdvisorWidget = () => {
  useEffect(() => {
    const scriptId = "tripadvisor-widget-script";

    // Function to create the widget
    const createWidget = () => {
      if (window.TripAdvisor) {
        window.TripAdvisor.loadWidgets();
      }
    };

    // Function to add the script dynamically
    const addScript = () => {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src =
          "https://www.jscache.com/wejs?wtype=cdsratingsonlynarrow&uniq=57&locationId=13197450&lang=en_US&border=true&display_version=2";
        script.async = true;
        script.onload = createWidget;
        document.body.appendChild(script);
      } else {
        createWidget();
      }
    };

    // Add the script when the component mounts
    addScript();
  }, []);

  return (
    <div className="tripadvisor-container">
      <div id="TA_cdsratingsonlynarrow57" className="TA_cdsratingsonlynarrow">
        <ul id="FAciTpF3" className="TA_links ElGz8QO">
          <li id="EBuZjvgTeI" className="VaBySe7 ">
            <a
              target="_blank"
              href="https://www.tripadvisor.com/Attraction_Review-g293890-d13197450-Reviews-Himalaya_Fair_Reisen-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Region.html"
            >
              <img
                src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg"
                alt="TripAdvisor"
                style={{ width: "250px" }}
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TripAdvisorWidget;
