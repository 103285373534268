import React from "react";
import "./About.scss";

function About() {
  return (
    <div className="about-container">
      <div className="about-header">
        <h1>Welcome To</h1>
        <h2>Himalayan Fair Reisen</h2>
      </div>
      <p className="about-description">
        The Himalayan regions of Nepal, Tibet, India & Bhutan have been our
        principle focus from the very beginning, and it is with this philosophy
        and inspiration that Himalayan Fair Reisen was founded. With over 28
        years of travel experience in this part of the world we take pride in
        offering you the very best in service and adventure. Our brochure, "A
        Window on the Himalayas & Beyond", includes a wide range of cultural
        tours, ethnic encounters, wilderness journeys, wildlife safari’s, hikes
        and treks designed to satisfy the discriminating traveler, those
        prepared to see remote areas and experience a very different culture and
        life style.
      </p>
    </div>
  );
}

export default About;
