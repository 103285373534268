// import React, { useState, useEffect } from "react";
// import { Link, NavLink } from "react-router-dom";
// import "./Navbar.scss";
// import logo1 from "../../assets/himalayanfern1.png";

// const Navbar = () => {
//   const [showMenu, setShowMenu] = useState(false);
//   const [scrolled, setScrolled] = useState(false);
//   const [dropdown, setDropdown] = useState(false);
//   const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
//   const [visible, setVisible] = useState(true);

//   const toggleMenu = () => {
//     setShowMenu(!showMenu);
//   };

//   const toggleDropdown = () => {
//     setDropdown(!dropdown);
//   };

//   const handleScroll = () => {
//     const currentScrollPos = window.pageYOffset;
//     const offset = currentScrollPos;

//     if (offset > 80) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }

//     setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
//     setPrevScrollPos(currentScrollPos);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [prevScrollPos, visible]);

//   return (
//     <nav
//       className={`container navbar ${scrolled ? "dark-nav" : ""} ${
//         visible ? "visible" : "hidden"
//       }`}
//     >
//       <Link to="/" className="navbar-left">
//         <img src={logo1} alt="DEKAT LOGO" />
//         <p>Himalaya Fair Reisen</p>
//       </Link>
//       <div className="navbar-right">
//         <div className="menu-toggle" onClick={toggleMenu}>
//           <div className={`toggle ${showMenu ? "active" : ""}`}>
//             <span></span>
//             <span></span>
//             <span></span>
//           </div>
//         </div>
//         <ul className={`nav-links ${showMenu ? "active" : ""}`}>
//           <li>
//             <NavLink exact to="/" onClick={toggleMenu}>
//               HOME
//             </NavLink>
//           </li>
//           <li>
//             <NavLink to="/activities" onClick={toggleMenu}>
//               ACTIVITES
//             </NavLink>
//           </li>
//           <li onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
//             <Link to="/destination">DESTINATION</Link>
//             {dropdown && (
//               <ul className="dropdown">
//                 <li>
//                   <Link to="/destination/nepal" onClick={toggleMenu}>
//                     Nepal
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/destination/india" onClick={toggleMenu}>
//                     India
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/destination/bhutan" onClick={toggleMenu}>
//                     Bhutan
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/destination/tibet" onClick={toggleMenu}>
//                     Tibet
//                   </Link>
//                 </li>
//               </ul>
//             )}
//           </li>
//           <li>
//             <NavLink to="/packages" onClick={toggleMenu}>
//               PACKAGES
//             </NavLink>
//           </li>
//           {/* <li>
//             <NavLink to="/about" onClick={toggleMenu}>
//               COMPANY
//             </NavLink>
//           </li> */}
//           <li>
//             <a
//               href="https://himalayafairreisen.blogspot.com"
//               target="_blank"
//               rel="noopener noreferrer"
//               onClick={toggleMenu}
//             >
//               BLOG
//             </a>
//           </li>

//           <li>
//             <NavLink to="/gallery" onClick={toggleMenu}>
//               GALLERY
//             </NavLink>
//           </li>
//           <li onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
//             <Link>ABOUT US</Link>
//             {dropdown && (
//               <ul className="dropdown">
//                 <li>
//                   <Link to="/about" onClick={toggleMenu}>
//                     COMPANY
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/contact" onClick={toggleMenu}>
//                     CONTACT US
//                   </Link>
//                 </li>
//               </ul>
//             )}
//           </li>
//         </ul>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.scss";
import logo1 from "../../assets/himalayanfern1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [dropdownDestination, setDropdownDestination] = useState(false);
  const [dropdownAbout, setDropdownAbout] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const offset = currentScrollPos;

    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  return (
    <nav
      className={`container navbar ${scrolled ? "dark-nav" : ""} ${
        visible ? "visible" : "hidden"
      }`}
    >
      <Link to="/" className="navbar-left">
        <img src={logo1} alt="DEKAT LOGO" />
        <p>Himalaya Fair Reisen</p>
      </Link>
      <div className="navbar-right">
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className={`toggle ${showMenu ? "active" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul className={`nav-links ${showMenu ? "active" : ""}`}>
          <li>
            <NavLink exact to="/" onClick={toggleMenu}>
              HOME
            </NavLink>
          </li>
          <li
            onMouseEnter={() => setDropdownAbout(true)}
            onMouseLeave={() => setDropdownAbout(false)}
          >
            <Link>ABOUT US</Link>
            {dropdownAbout && (
              <ul className="dropdown">
                <li>
                  <Link to="/about" onClick={toggleMenu}>
                    Company
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toggleMenu}>
                    Contact
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <NavLink to="/activities" onClick={toggleMenu}>
              ACTIVITIES
            </NavLink>
          </li>
          <li
            onMouseEnter={() => setDropdownDestination(true)}
            onMouseLeave={() => setDropdownDestination(false)}
          >
            <Link to="/destination">DESTINATION</Link>
            {dropdownDestination && (
              <ul className="dropdown">
                <li>
                  <Link to="/destination/nepal" onClick={toggleMenu}>
                    Nepal
                  </Link>
                </li>
                <li>
                  <Link to="/destination/india" onClick={toggleMenu}>
                    India
                  </Link>
                </li>
                <li>
                  <Link to="/destination/bhutan" onClick={toggleMenu}>
                    Bhutan
                  </Link>
                </li>
                <li>
                  <Link to="/destination/tibet" onClick={toggleMenu}>
                    Tibet
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <NavLink to="/packages" onClick={toggleMenu}>
              PACKAGES
            </NavLink>
          </li>
          <li>
            <a
              href="https://himalayafairreisen.blogspot.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMenu}
            >
              BLOG
            </a>
          </li>

          <li>
            <NavLink to="/gallery" onClick={toggleMenu}>
              GALLERY
            </NavLink>
          </li>

          <li>
            <NavLink to="/search" onClick={toggleMenu}>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ height: "22px" }}
              />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
