import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "./Destination.scss";

import destination1 from "../../../assets/DestinationNepal.jpg";
import destination2 from "../../../assets/DestinationIndia.avif";
import destination3 from "../../../assets/DestinationTibet.jpeg";
import destination4 from "../../../assets/DestinationBhutan.avif";

function Destination() {
  const navigate = useNavigate(); // Hook for navigation

  const destinations = [
    { image: destination1, name: "Nepal" },
    { image: destination2, name: "India" },
    { image: destination3, name: "Tibet" },
    { image: destination4, name: "Bhutan" },
  ];

  const navigateToDestination = (name) => {
    navigate(`/destination/${name}`);
  };

  return (
    <div className="destination-container">
      <h2>Destinations</h2>
      <hr
        style={{
          width: "140px",
          height: "6px",
          borderRadius: "10px",
          border: "none",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
          marginBottom: "80px",
        }}
      />
      <div className="carousel">
        {destinations.map((dest, idx) => (
          <div key={idx}>
            <div
              className="destination"
              onClick={() => navigateToDestination(dest.name)}
            >
              <img src={dest.image} alt={dest.name} />
            </div>
            <p className="name">{dest.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Destination;
