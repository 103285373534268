import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
// import BookingCard from "../../../components/BookingCard/BookingCard";
import axios from "axios";
import "./TravelRoute.scss";
import nepalImage from "../../assets/NepalBanner.jpg";
import TrekRoutes from "../../components/TrekRoutes/TrekRoutes";

function Nepal() {
  const [destinations, setDestinations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDestinations();
  }, []);

  const fetchDestinations = async () => {
    try {
      const response = await axios.get(
        "https://backend.himalayafairreisen.com/destinations"
      );
      const filteredDestinations = response.data.filter(
        (destination) => destination.country === "Nepal"
      );
      setDestinations(filteredDestinations);
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };

  const handleViewDetails = (id) => {
    navigate(`/route/${id}`);
  };

  return (
    <div className="nepal-container">
      <Banner img={nepalImage} title="Nepal" />
      {destinations.map((destination) => (
        <TrekRoutes
          key={destination.id}
          image={`https://backend.himalayafairreisen.com/destinations/${destination.id}/image`}
          trekName={destination.title}
          description={destination.description}
          price={destination.price}
          onViewDetails={() => handleViewDetails(destination.id)}
        />
      ))}
    </div>
  );
}

export default Nepal;
