import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./Screens/Home/Home";
import Contact from "./Screens/Contact/Contact";
import About from "./Screens/About/About";
import Activites from "./Screens/Activites/Activites";
import Destination from "./Screens/Destination/Destination";
import Nepal from "./Screens/Destination/Countries/Nepal";
import India from "./Screens/Destination/Countries/India";
import Bhutan from "./Screens/Destination/Countries/Bhutan";
import Tibet from "./Screens/Destination/Countries/Tibet";
import ScrollToTop from "./ScrollToTop";
import Packages from "./Screens/Packages/Packages";
import TripDetail from "./Screens/Home/Trips/TripDetail";
import DestinationDetail from "./Screens/Destination/DestinationDetail";
import PackageDetail from "./Screens/Packages/PackageDetails";
import TravelRoute from "./Screens/Routes/TravelRoute";
import RouteDetails from "./Screens/Routes/RouteDetails/RouteDetails";
import "./App.css";
import Gallery from "./Screens/Home/Gallery/Gallery";
import Search from "./Screens/Search/Search";
import WhatsApp from "./components/Widgets/Whatsapp";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/package/:id" element={<PackageDetail />} />
        <Route path="/activities" element={<Activites />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/search" element={<Search />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/destination/Nepal" element={<Nepal />} />
        <Route path="/destination/India" element={<India />} />
        <Route path="/destination/Bhutan" element={<Bhutan />} />
        <Route path="/destination/Tibet" element={<Tibet />} />
        <Route path="/trip/:id" element={<TripDetail />} />
        <Route path="/destination/:id" element={<DestinationDetail />} />
        <Route path="/route" element={<TravelRoute />} />
        <Route path="/route-details/:routeTitle" element={<RouteDetails />} />
      </Routes>
      <WhatsApp />
      <Footer />
    </BrowserRouter>
  );
};

export default App;
