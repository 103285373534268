import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Nepal.scss";
import Banner from "../../../components/Banner/Banner";
import nepalImage from "../../../assets/NepalBanner.jpg";
import day from "../../../assets/day.png";
import trek from "../../../assets/trekk.png";
import money from "../../../assets/money.png";
import height from "../../../assets/altitude.png";

const API_URL = "https://backend.himalayafairreisen.com";

const Nepal = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDays, setSelectedDays] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [difficultyLevel, setDifficultyLevel] = useState(null);
  const [groupTravel, setGroupTravel] = useState(null);
  const [hotelAccommodation, setHotelAccommodation] = useState(null);
  const [categories, setCategories] = useState([]);
  const [travels, setTravels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    fetchCategories();
    fetchAllTravels();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
      alert("Failed to fetch categories. Please try again.");
    }
  };

  const fetchAllTravels = async () => {
    setIsLoading(true); // Set loading to true before fetching
    try {
      const params = {
        country: "Nepal",
        popularity: true,
      };
      const response = await axios.get(`${API_URL}/travel`, { params });
      setTravels(response.data);
    } catch (error) {
      console.error("Error fetching travels:", error.message);
      alert("Failed to fetch travels. Please try again.");
    } finally {
      setIsLoading(false); // Set loading to false after fetching is done
    }
  };

  const fetchFilteredTravels = async () => {
    setIsLoading(true); // Set loading to true before fetching
    try {
      let daysValue = null;
      if (selectedDays === "5") daysValue = 3;
      else if (selectedDays === "10") daysValue = 8;
      else if (selectedDays === "15") daysValue = 13;
      else if (selectedDays === ">15") daysValue = 18;

      let priceValue = null;
      if (selectedPrice === "1000") priceValue = 500;
      else if (selectedPrice === "2000") priceValue = 1500;
      else if (selectedPrice === "3000") priceValue = 2500;
      else if (selectedPrice === "4000") priceValue = 3500;
      else if (selectedPrice === ">4000") priceValue = 4500;

      const params = {
        category: selectedType || null,
        days: daysValue,
        price: priceValue,
        difficulty_level: difficultyLevel || null,
        group_travel: groupTravel !== null ? Boolean(groupTravel) : null,
        hotel_accommodation:
          hotelAccommodation !== null ? Boolean(hotelAccommodation) : null,
        country: "Nepal",
      };

      const response = await axios.get(`${API_URL}/travel`, { params });
      setTravels(response.data);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching filtered travels:", error.message);
      alert("Failed to fetch filtered travels. Please try again.");
    } finally {
      setIsLoading(false); // Set loading to false after fetching is done
    }
  };

  const handleCategoryChange = (id) => {
    setSelectedType((prev) => (prev === id ? null : id));
  };

  const handleDifficultyChange = (level) => {
    setDifficultyLevel((prev) =>
      prev === level.toString() ? null : level.toString()
    );
  };

  const handleDaysChange = (value) => {
    setSelectedDays((prev) => (prev === value ? null : value));
  };

  const handlePriceChange = (value) => {
    setSelectedPrice((prev) => (prev === value ? null : value));
  };

  const resetFilters = () => {
    setSelectedType(null);
    setSelectedDays(null);
    setSelectedPrice(null);
    setDifficultyLevel(null);
    setGroupTravel(null);
    setHotelAccommodation(null);
    fetchAllTravels();
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTravels = travels.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(travels.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDiscoverClick = (id) => {
    navigate(`/destination/${id}`);
  };

  const isFilterApplied =
    selectedType ||
    selectedDays ||
    selectedPrice ||
    difficultyLevel ||
    groupTravel !== null ||
    hotelAccommodation !== null;

  return (
    <div>
      <Banner img={nepalImage} title="Nepal" />
      <div className="travel-layout">
        {/* Filter Section */}
        <div className="filter-container">
          <h2>Search</h2>

          {/* Type of Travel */}
          <label
            style={{
              fontSize: "16px",
              marginTop: "40px",
              marginBottom: "20px",
            }}
          >
            Type of Travel:
          </label>
          <div className="travel-type">
            {categories.map((category) => (
              <div className="travel-select1" key={category.id}>
                <div className="checkbox-wrapper-12">
                  <div className="cbx">
                    <input
                      type="checkbox"
                      id={`category-${category.id}`}
                      name="category"
                      checked={selectedType === category.id}
                      onChange={() => handleCategoryChange(category.id)}
                    />
                    <label htmlFor={`category-${category.id}`}></label>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </div>
                  <span style={{ marginLeft: "18px", fontSize: "14px" }}>
                    {category.title}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {/* Days */}
          <label style={{ fontSize: "16px", marginTop: "40px" }}>
            Max Days:
          </label>
          <div className="travel-select">
            {["5", "10", "15", ">15"].map((days, index) => (
              <div className="checkbox-wrapper-12" key={index}>
                <div className="cbx">
                  <input
                    type="checkbox"
                    id={`days-${days}`}
                    name="days"
                    checked={selectedDays === days}
                    onChange={() => handleDaysChange(days)}
                  />
                  <label htmlFor={`days-${days}`}></label>
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                  </svg>
                </div>
                <span style={{ marginLeft: "18px", fontSize: "15px" }}>
                  {index === 0
                    ? "0 - 5"
                    : index === 1
                    ? "6 - 10"
                    : index === 2
                    ? "11 - 15"
                    : "More than 15"}
                </span>
              </div>
            ))}
          </div>

          {/* Price */}
          <label style={{ fontSize: "16px", marginTop: "40px" }}>Price:</label>
          <div className="travel-select">
            {["1000", "2000", "3000", "4000", ">4000"].map((price, index) => (
              <div className="checkbox-wrapper-12" key={index}>
                <div className="cbx">
                  <input
                    type="checkbox"
                    id={`price-${price}`}
                    name="price"
                    checked={selectedPrice === price}
                    onChange={() => handlePriceChange(price)}
                  />
                  <label htmlFor={`price-${price}`}></label>
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                  </svg>
                </div>
                <span style={{ marginLeft: "18px", fontSize: "15px" }}>
                  {index === 0
                    ? "Less than 1000 €"
                    : index === 4
                    ? "≥ 4000 €"
                    : `${price - 1000} - ${price} €`}
                </span>
              </div>
            ))}
          </div>

          {/* Difficulty Level */}
          <label style={{ fontSize: "15px", marginTop: "45px" }}>
            Difficulty Level:
          </label>
          <div className="difficulty">
            {[1, 2, 3, 4, 5].map((level) => (
              <div className="travel-select" key={level}>
                <div className="checkbox-wrapper-12">
                  <div className="cbx">
                    <input
                      type="checkbox"
                      id={`difficulty-${level}`}
                      name="difficulty"
                      checked={difficultyLevel === level.toString()}
                      onChange={() => handleDifficultyChange(level.toString())}
                    />
                    <label htmlFor={`difficulty-${level}`}></label>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </div>
                  <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                    {level}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {/* Group Travel */}
          <div className="grp-travel">
            <label style={{ fontSize: "15px" }}>Group Travel</label>
            <div className="travel-select">
              <div className="checkbox-wrapper-12">
                <div className="cbx">
                  <input
                    type="checkbox"
                    id="grp"
                    checked={groupTravel === 1}
                    onChange={(e) =>
                      setGroupTravel(e.target.checked ? 1 : null)
                    }
                  />
                  <label htmlFor="grp"></label>
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Hotel Accommodation */}
          <div className="hotel">
            <label style={{ fontSize: "15px" }}>Hotel Accommodation</label>
            <div className="travel-select">
              <div className="checkbox-wrapper-12">
                <div className="cbx">
                  <input
                    type="checkbox"
                    id="hotel"
                    checked={hotelAccommodation === 1}
                    onChange={(e) =>
                      setHotelAccommodation(e.target.checked ? 1 : null)
                    }
                  />
                  <label htmlFor="hotel"></label>
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M2 8.36364L6.23077 12L13 2"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Apply Filters Button */}
          <button
            className="apply"
            type="button"
            onClick={fetchFilteredTravels}
          >
            Apply Filters
          </button>

          {/* Reset Filters Button: Show only if filters are applied */}
          {isFilterApplied && (
            <button className="reset" type="button" onClick={resetFilters}>
              Reset Filters
            </button>
          )}
        </div>

        {/* Results Section */}
        <div className="results-container">
          <h2 className="title-travel-result">Available Trips :</h2>
          {isLoading ? ( // Conditionally render the loader when isLoading is true
            <div className="loader" style={{ marginTop: "130px" }}>
              <div className="cssloader">
                <div className="triangle1"></div>
                <div className="triangle2"></div>
                <p className="text">Please Wait</p>
              </div>
            </div>
          ) : currentTravels.length > 0 ? (
            <ul className="trip-list">
              {currentTravels.map((travel) => (
                <li key={travel.id} className="trip-item">
                  <img
                    src={`${API_URL}/travel/${travel.id}/image`}
                    alt={travel.title}
                    className="travel-image"
                  />
                  <div className="trip-info">
                    <h4>
                      {travel.country} - {travel.title}
                    </h4>
                    <p>
                      {travel.description.length > 190
                        ? `${travel.description.substring(0, 190)}...`
                        : travel.description}
                    </p>
                    <div className="indicator-icons">
                      <p className="indicator-box">
                        <strong>
                          <img className="indicator" src={day} />
                        </strong>
                        {travel.days} days
                      </p>
                      <p className="indicator-box">
                        <strong>
                          <img className="indicator" src={money} />
                        </strong>
                        €
                        {typeof travel.price === "number"
                          ? travel.price.toFixed(2)
                          : "N/A"}
                      </p>
                      <p className="indicator-box">
                        <img className="indicator" src={height} alt="" />
                        {travel.maximum_altitude} m
                      </p>
                      <p className="indicator-box">
                        <strong>
                          <img className="indicator" src={trek} />
                        </strong>
                        {travel.difficulty_level}
                      </p>
                    </div>
                    <button
                      className="discover-button"
                      onClick={() => handleDiscoverClick(travel.id)}
                    >
                      Discover
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No travels found based on the selected filters.</p>
          )}

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button
              className="previous-page"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <span>Page {currentPage}</span>
            <button
              className="next-page"
              onClick={handleNextPage}
              disabled={
                currentPage === Math.ceil(travels.length / itemsPerPage)
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nepal;
