import React from "react";
import "./Banner.scss";

function Banner({ img, title }) {
  return (
    <div className="banner" style={{ backgroundImage: `url(${img})` }}>
      <h1>{title}</h1>
    </div>
  );
}

export default Banner;
