import React from "react";
import {
  FaHeart,
  FaLeaf,
  FaHandsHelping,
  FaLightbulb,
  FaEye,
  FaHandshake,
} from "react-icons/fa";
import "./Values.scss";

const values = [
  {
    icon: <FaHeart className="value-icon" />,
    title: "Compassion",
    description:
      "We approach our mission with boundless compassion and empathy, recognizing the inherent dignity and worth of every individual we serve.",
  },
  {
    icon: <FaLeaf className="value-icon" />,
    title: "Sustainable",
    description:
      "Ensuring that our programs and initiatives are environmentally sustainable and have a long-lasting positive impact on the community.",
  },
  {
    icon: <FaHandsHelping className="value-icon" />,
    title: "Support",
    description:
      "Working together with partners, volunteers, and the community to achieve common goals and maximize our impact.",
  },
  {
    icon: <FaLightbulb className="value-icon" />,
    title: "Innovation",
    description:
      "Embracing creativity and innovation to find new and effective ways to address social challenges and improve the lives of others.",
  },
  {
    icon: <FaEye className="value-icon" />,
    title: "Transparency",
    description:
      "Being open and honest about our actions, decisions, and use of resources, and maintaining clear communication with stakeholders.",
  },
  {
    icon: <FaHandshake className="value-icon" />,
    title: "Dignity",
    description:
      "Respecting the inherent dignity and worth of every individual, and treating them with kindness, empathy, and respect.",
  },
];

function Values() {
  return (
    <div
      className="values-container"
      style={{
        marginTop: "80px",
      }}
    >
      <h2>Our Values</h2>
      <hr
        style={{
          width: "100px",
          height: "6px",
          borderRadius: "10px",
          border: "none",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
          marginBottom: "80px",
        }}
      />
      <div
        className="values-grid"
        style={{
          marginBottom: "180px",
        }}
      >
        {values.map((value, index) => (
          <div key={index} className="value-item">
            {value.icon}
            <h3>{value.title}</h3>
            <p>{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Values;
