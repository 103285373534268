// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import Banner from "../../components/Banner/Banner";
// import axios from "axios";
// import "./Packages.scss";
// import BannerImg from "../../assets/bg-activity.png";

// function Packages() {
//   const [packages, setPackages] = useState([]);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const activity = location.state?.activity;

//   useEffect(() => {
//     fetchPackages();
//   }, [activity]);

//   const fetchPackages = async () => {
//     try {
//       const response = await axios.get(
//         "https://backend.himalayafairreisen.com/packages"
//       );
//       const allPackages = response.data;
//       if (activity) {
//         const filteredPackages = allPackages.filter(
//           (pkg) => pkg.activity === activity
//         );
//         setPackages(filteredPackages);
//       } else {
//         setPackages(allPackages);
//       }
//     } catch (error) {
//       console.error("Error fetching packages:", error);
//     }
//   };

//   const handleDiscover = (id) => {
//     navigate(`/package/${id}`);
//   };

//   const truncateDescription = (description, maxLength) => {
//     if (description.length <= maxLength) {
//       return description;
//     }
//     return `${description.substring(0, maxLength)} . . .`;
//   };

//   return (
//     <div>
//       <Banner img={BannerImg} title="Packages" />
//       <header className="packages-header">
//         <h2>Explore Our Adventure Packages</h2>
//         <p>
//           Discover exciting destinations and experiences designed to make your
//           adventures unforgettable.
//         </p>
//       </header>
//       <div>
//         {packages.map((pkg) => (
//           <div key={pkg.id} className="card">
//             <img
//               src={`https://backend.himalayafairreisen.com/packages/${pkg.id}/image`}
//               alt={pkg.title}
//               className="card-image"
//             />
//             <div className="card-content">
//               <div className="content-1">
//                 <p className="package-destination">
//                   {pkg.country} | {pkg.title}
//                 </p>
//                 <h3>{pkg.title}</h3>
//                 <p>{truncateDescription(pkg.description, 300)}</p>
//                 <ul>
//                   {pkg.features &&
//                     pkg.features
//                       .split(",")
//                       .map((feature, idx) => <li key={idx}>{feature}</li>)}
//                 </ul>
//                 <div className="package-footer">
//                   <p>
//                     Price : <span className="price">{pkg.price}€</span>
//                   </p>
//                   <button onClick={() => handleDiscover(pkg.id)}>
//                     Discover
//                   </button>
//                 </div>
//               </div>
//               <div className="bg-design">
//                 <div className="circle"></div>
//                 <div className="circle" id="right"></div>
//                 <div className="circle" id="bottom"></div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Packages;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import axios from "axios";
import "./Packages.scss";
import BannerImg from "../../assets/bg-activity.png";

function Packages() {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const navigate = useNavigate();
  const location = useLocation();
  const activity = location.state?.activity;

  useEffect(() => {
    fetchPackages();
  }, [activity]);

  const fetchPackages = async () => {
    setIsLoading(true); // Set loading to true before fetching data
    try {
      const response = await axios.get(
        "https://backend.himalayafairreisen.com/packages"
      );
      const allPackages = response.data;
      if (activity) {
        const filteredPackages = allPackages.filter(
          (pkg) => pkg.activity === activity
        );
        setPackages(filteredPackages);
      } else {
        setPackages(allPackages);
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      setIsLoading(false); // Set loading to false after data is fetched or if an error occurs
    }
  };

  const handleDiscover = (id) => {
    navigate(`/package/${id}`);
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
      return description;
    }
    return `${description.substring(0, maxLength)} . . .`;
  };

  return (
    <div>
      <Banner img={BannerImg} title="Packages" />
      <header className="packages-header">
        <h2>Explore Our Adventure Packages</h2>
        <p>
          Discover exciting destinations and experiences designed to make your
          adventures unforgettable.
        </p>
      </header>

      {isLoading ? ( // Conditional rendering: show loader if isLoading is true
        <div className="loader" style={{ marginTop: "40px" }}>
          {" "}
          <div className="cssloader">
            <div className="triangle1"></div>
            <div className="triangle2"></div>
            <p className="text">Please Wait</p>
          </div>
        </div>
      ) : (
        <div>
          {packages.map((pkg) => (
            <div key={pkg.id} className="card">
              <img
                src={`https://backend.himalayafairreisen.com/packages/${pkg.id}/image`}
                alt={pkg.title}
                className="card-image"
              />
              <div className="card-content">
                <div className="content-1">
                  <p className="package-destination">
                    {pkg.country} | {pkg.title}
                  </p>
                  <h3>{pkg.title}</h3>
                  <p>{truncateDescription(pkg.description, 300)}</p>
                  <ul>
                    {pkg.features &&
                      pkg.features
                        .split(",")
                        .map((feature, idx) => <li key={idx}>{feature}</li>)}
                  </ul>
                  <div className="package-footer">
                    <p>
                      Price : <span className="price">{pkg.price}€</span>
                    </p>
                    <button onClick={() => handleDiscover(pkg.id)}>
                      Discover
                    </button>
                  </div>
                </div>
                <div className="bg-design">
                  <div className="circle"></div>
                  <div className="circle" id="right"></div>
                  <div className="circle" id="bottom"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Packages;
