import React from "react";
import "./MeetUs.scss";

import person1 from "../../../assets/banner3.jpeg"; // Assuming different images for each person
import Martin from "../../../assets/Martin.jpg"; // Assuming different images for each person
import Allan from "../../../assets/Allan.jpg"; // Assuming different images for each person
import person4 from "../../../assets/banner3.jpeg"; // Assuming different images for each person
import person5 from "../../../assets/banner3.jpeg"; // Assuming different images for each person
import person6 from "../../../assets/banner3.jpeg"; // Assuming different images for each person

function MeetUs() {
  const teamMembers = [
    { name: "Hari Gautam", role: "Chairman", image: person1 },
    { name: "Martin Gautam", role: "General Manager", image: Martin },
    { name: "Allan Gautam", role: "Tech Support", image: Allan },
    // { name: "Santosh Maldar", role: "Architect", image: person3 },
    // { name: "Pradeep Rajput", role: "Software Engineer", image: person4 },
    // { name: "Jade Zhenhua", role: "Data Scientist", image: person5 },
    // { name: "Raj Kumar", role: "HR Manager", image: person6 },
  ];

  return (
    <div className="team-container">
      <h2>Meet Our Excellent Team</h2>
      <hr
        style={{
          width: "330px",
          height: "6px",
          borderRadius: "10px",
          border: "none",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
          marginBottom: "80px",
        }}
      />
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.image} alt={member.name} />
            <h3>{member.name}</h3>
            <p>{member.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MeetUs;
