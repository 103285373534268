import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DetailLayout from "../../../components/DetailLayout/DetailLayout";

function TripDetail() {
  const { id } = useParams();
  const [trip, setTrip] = useState(null);

  useEffect(() => {
    fetchTrip();
  }, [id]);

  const fetchTrip = async () => {
    try {
      const response = await axios.get(
        `https://backend.himalayafairreisen.com/trips/${id}`
      );
      const tripData = response.data;
      // Parse itinerary into array of objects
      tripData.itinerary = parseItinerary(tripData.itinerary);
      tripData.bannerImg = `https://backend.himalayafairreisen.com/trips/${id}/image`;
      setTrip(tripData);
    } catch (error) {
      console.error("Error fetching trip:", error);
    }
  };

  const parseItinerary = (itineraryText) => {
    const days = itineraryText.split(/Day \d+:/).filter((day) => day.trim());
    return days.map((day, index) => {
      const [title, ...content] = day.trim().split("\n");
      return {
        day: `Day ${index + 1}`,
        title: title.trim(),
        content: content.join("\n").trim(),
      };
    });
  };

  if (!trip) {
    return <div>Loading...</div>;
  }

  return (
    <DetailLayout
      id={id}
      title={trip.name}
      bannerImg={trip.bannerImg}
      description={trip.description}
      price={trip.price}
      itinerary={trip.itinerary}
      tourGuide={trip.tour_guide_name}
      map={trip.map}
      included={trip.included}
      notIncluded={trip.not_included}
    />
  );
}

export default TripDetail;
