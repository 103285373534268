import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Trips.scss";
import axios from "axios";

function Trips() {
  const navigate = useNavigate();
  const [tours, setTours] = useState([]);

  useEffect(() => {
    fetchTours();
  }, []);

  const fetchTours = async () => {
    try {
      const response = await axios.get(
        "https://backend.himalayafairreisen.com/trips"
      );
      setTours(response.data);
    } catch (error) {
      console.error("Error fetching trips:", error);
    }
  };

  const handleCardClick = (id) => {
    navigate(`/trip/${id}`);
  };

  return (
    <div className="trips-container">
      <h1>Join Our Adventure</h1>
      <hr
        style={{
          width: "280px",
          height: "6px",
          borderRadius: "10px",
          border: "none",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
          marginBottom: "80px",
        }}
      />
      <Slider
        {...{
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        }}
      >
        {tours.map((tour) => (
          <div
            key={tour.id}
            onClick={() => handleCardClick(tour.id)}
            class="card-container"
          >
            <div class="trip-card">
              <div class="img-content">
                <img
                  src={`https://backend.himalayafairreisen.com/trips/${tour.id}/image`}
                  alt={tour.name}
                  className="trip-image"
                />
              </div>
              <div class="content">
                <h2>{tour.name}</h2>
                <p className="trip-description">
                  {tour.description.length > 100
                    ? `${tour.description.substring(0, 100)}...`
                    : tour.description}
                </p>
                <p className="trip-price">${tour.price}</p>
              </div>
            </div>
          </div>
          // <div
          //   key={tour.id}
          //   className="trip-card"
          //   onClick={() => handleCardClick(tour.id)}
          // >
          //   <img
          //     src={`https://backend.himalayafairreisen.com/trips/${tour.id}/image`}
          //     alt={tour.name}
          //     className="trip-image"
          //   />
          //   <div className="trip-content">
          //     <h2>{tour.name}</h2>
          //     <p className="trip-description">
          //       {tour.description.length > 100
          //         ? `${tour.description.substring(0, 100)}...`
          //         : tour.description}
          //     </p>
          //     <p className="trip-price">${tour.price}</p>
          //   </div>
          // </div>
        ))}
      </Slider>
    </div>
  );
}

export default Trips;
