import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DetailLayout from "../../components/DetailLayout/DetailLayout";
import Bannerimg from "../../assets/banner3.jpeg";

function PackageDetail() {
  const { id } = useParams();
  const [pkg, setPkg] = useState(null);

  useEffect(() => {
    fetchPackage();
  }, [id]);

  const fetchPackage = async () => {
    try {
      const response = await axios.get(
        `https://backend.himalayafairreisen.com/packages/${id}`
      );
      const packageData = response.data;
      // Parse itinerary into array of objects
      packageData.itinerary = parseItinerary(packageData.itinerary);
      packageData.bannerImg = `https://backend.himalayafairreisen.com/packages/${id}/image`;
      setPkg(packageData);
    } catch (error) {
      console.error("Error fetching package:", error);
    }
  };

  const parseItinerary = (itineraryText) => {
    const days = itineraryText.split(/Day \d+:/).filter((day) => day.trim());
    return days.map((day, index) => {
      const [title, ...content] = day.trim().split("\n");
      return {
        day: `Day ${index + 1}`,
        title: title.trim(),
        content: content.join("\n").trim(),
      };
    });
  };

  if (!pkg) {
    return (
      <div className="loader" style={{ marginTop: "10%" }}>
        <div className="cssloader">
          <div className="triangle1"></div>
          <div className="triangle2"></div>
          <p className="text">Please Wait</p>
        </div>
      </div>
    );
  }

  return (
    <DetailLayout
      id={id} // Pass id to DetailLayout
      title={pkg.title}
      bannerImg={pkg.bannerImg}
      description={pkg.description}
      price={pkg.price}
      itinerary={pkg.itinerary}
      map={pkg.map}
      included={pkg.included}
      notIncluded={pkg.not_included}
    />
  );
}

export default PackageDetail;
