import React, { useState } from "react";
import Banner from "../Banner/Banner";
import ContactForm from "../ContactForm/ContactForm";
import "./DetailLayout.scss";

const DetailLayout = ({
  id,
  title,
  bannerImg,
  description,
  price,
  itinerary,
  map,
  included,
  notIncluded,
  tourGuide,
}) => {
  const [activeTab, setActiveTab] = useState("itinerary");
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderTabContent = () => {
    if (activeTab === "itinerary") {
      return (
        <div className="detail-itinerary">
          <h2>Itinerary</h2>
          {itinerary.map((day, index) => (
            <div key={index} className="itinerary-day">
              <div className="itenary-content1">
                <button
                  className={`collapsible ${
                    activeIndex === index ? "collapsible-active" : ""
                  }`}
                  onClick={() => handleToggle(index)}
                ></button>
                <h4>
                  {day.day}: {day.title}
                </h4>
              </div>
              <div
                className="itenary-content2"
                style={{ maxHeight: activeIndex === index ? "90%" : "0" }}
              >
                <p>{day.content}</p>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (activeTab === "map") {
      return (
        <div className="detail-map">
          <h3>Map</h3>
          <iframe
            src={map}
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Map"
          ></iframe>
        </div>
      );
    }
  };

  return (
    <div className="detail-container">
      <Banner img={bannerImg} title={title} />
      <div className="detail-content">
        <div className="detail-details">
          <h2>Details</h2>
          <p>{description}</p>
          <hr
            style={{
              width: "100%",
              height: "3px",
              borderRadius: "10px",
              border: "none",
              background:
                "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
              marginBottom: "48px",
              marginTop: "49px",
            }}
          />
          {price && <p className="detail-price">{price}</p>}

          {included && (
            <div className="detail-included">
              <h3>Included:</h3>
              <ul>
                {included
                  .split(".")
                  .map(
                    (item, index) =>
                      item.trim() && <li key={index}>{item.trim()}.</li>
                  )}
              </ul>
            </div>
          )}
          <hr
            style={{
              width: "100%",
              height: "3px",
              borderRadius: "10px",
              border: "none",
              background:
                "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
              marginBottom: "48px",
              marginTop: "49px",
            }}
          />
          {notIncluded && (
            <div className="detail-not-included">
              <h3>Not Included:</h3>
              <ul>
                {notIncluded
                  .split(".")
                  .map(
                    (item, index) =>
                      item.trim() && <li key={index}>{item.trim()}.</li>
                  )}
              </ul>
            </div>
          )}
          <hr
            style={{
              width: "100%",
              height: "3px",
              borderRadius: "10px",
              border: "none",
              background:
                "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
              marginBottom: "48px",
              marginTop: "49px",
            }}
          />
          <div className="detail-tabs">
            <button
              className={`tab-button ${
                activeTab === "itinerary" ? "active" : ""
              }`}
              onClick={() => setActiveTab("itinerary")}
            >
              Itinerary
            </button>
            <button
              className={`tab-button ${activeTab === "map" ? "active" : ""}`}
              onClick={() => setActiveTab("map")}
            >
              Map
            </button>
          </div>
          {renderTabContent()}
          {tourGuide && (
            <p className="detail-tour-guide">Tour Guide: {tourGuide}</p>
          )}
        </div>
        <div className="detail-contact">
          <ContactForm id={id} />
        </div>
      </div>
    </div>
  );
};

export default DetailLayout;
