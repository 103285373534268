import React from "react";
import "./TrekRoutes.scss";
import day from "../../assets/day.png";
import height from "../../assets/altitude.png";

const TrekRoutes = ({
  image,
  trekName,
  days,
  altitude,
  price,
  onViewDetails,
}) => {
  return (
    <div className="booking-card">
      <div className="booking-image">
        <img src={image} alt={trekName} />
      </div>
      <div className="booking-info">
        <h3 className="booking-title">{trekName}</h3>
        <div className="booking-details">
          <div className="card-icons">
            <p className="booking-days">
              <img src={day} alt="" />
              {days}days
            </p>
            <p className="booking-altitude">
              <img src={height} alt="" />
              {altitude} meters
            </p>
          </div>

          <div className="price-tag">
            {/* <span>From</span> */}
            <span>
              {price && <p className="detail-price">{price}</p>}
              {/* {price} */}
            </span>
          </div>
          <button className="details-button" onClick={onViewDetails}>
            VIEW DETAILS
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrekRoutes;
