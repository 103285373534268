import React from "react";
import { FaHiking, FaLeaf, FaGlobe, FaMountain } from "react-icons/fa";
import "./Perks.scss";

const values = [
  {
    icon: <FaHiking className="value-icon" />,
    title: "Adventure Trekking & Tours",
    description:
      "Adventure tours are travel experiences that offer a more active and immersive way of exploring a destination. These tours typically involve activities such as hiking, biking, kayaking, rafting, and other outdoor activities.",
  },
  {
    icon: <FaLeaf className="value-icon" />,
    title: "Wild Life & Nature Experience",
    description:
      "Wildlife treks are outdoor adventures that allow you to explore natural habitats and observe wild animals in their natural environments. These treks can take you through jungles, forests, mountains, and other remote areas where wildlife thrives.",
  },
  {
    icon: <FaGlobe className="value-icon" />,
    title: "Global Culture Experience",
    description:
      "We offer guided tours and travel experiences to help people immerse themselves in the culture of a particular region or country. These tours focus on providing a deeper understanding and appreciation of the local customs, traditions, history, and cuisine.",
  },
  {
    icon: <FaMountain className="value-icon" />,
    title: "Mountain Climbing & Trekking",
    description:
      "A mountain climbing service is a specialized offering provided by our companies that helps adventure enthusiasts to climb mountains safely and efficiently.",
  },
];

function Perks() {
  return (
    <div className="values-container">
      <h2>Perks</h2>
      <hr
        style={{
          width: "80px",
          height: "6px",
          borderRadius: "10px",
          border: "none",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,71,121,1) 31%, rgba(0,212,255,1) 76%)",
          marginBottom: "80px",
        }}
      />
      <div className="values-grid">
        {values.map((value, index) => (
          <div key={index} className="value-item">
            {value.icon}
            <h3>{value.title}</h3>
            <p>{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Perks;
