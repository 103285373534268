import React, { useState } from "react";
import axios from "axios";
import "./ContactForm.scss";

const ContactForm = ({ id }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const inquiryData = {
        name,
        email,
        message,
        id,
      };
      await axios.post(
        "https://backend.himalayafairreisen.com/email/inquiry",
        inquiryData
      );
      alert("Inquiry sent successfully");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error sending inquiry:", error);
      alert("Failed to send inquiry");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form-container">
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Message:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
      </div>
      <button type="submit">Send Inquiry</button>
    </form>
  );
};

export default ContactForm;
