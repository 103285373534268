import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEnvelope,
  faInbox,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import BannerImg from "../../assets/banner3.jpeg";
import Banner from "../../components/Banner/Banner";
import MapImage from "../../assets/map-bg.png"; // Update the path to your image

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const inputs = document.querySelectorAll(".contact-input");
    inputs.forEach((ipt) => {
      ipt.addEventListener("focus", () => {
        ipt.parentNode.classList.add("focus");
        ipt.parentNode.classList.add("not-empty");
      });
      ipt.addEventListener("blur", () => {
        if (ipt.value === "") {
          ipt.parentNode.classList.remove("not-empty");
        }
        ipt.parentNode.classList.remove("focus");
      });
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post("https://backend.himalayafairreisen.com/email/contact", {
        name,
        email,
        subject,
        message,
      });
      alert("Message sent successfully");
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message");
    }
  };

  return (
    <div className="contact-container">
      <Banner img={BannerImg} title="Contact Us" />
      <div className="contact-content">
        <div className="left-side">
          <div className="iframe-bg">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.234749738114!2d85.2874313!3d27.7243125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb199c410f6603%3A0x792242cfcfe80fbd!2sHimalaya%20Fair%20Reisen!5e0!3m2!1sen!2snp!4v1626960967962!5m2!1sen!2snp"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="image-container">
            <img src={MapImage} alt="Map Illustration" />
          </div>
        </div>

        <div className="contact-form-container">
          <h2>If You Have Any Query,</h2>
          <p>Please Contact Us</p>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="input-wrap w-100">
                <input
                  className="contact-input"
                  autoComplete="off"
                  name="Name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <label>Name</label>
                <FontAwesomeIcon className="icons" icon={faAddressCard} />
              </div>
              {/* <div className="input-wrap">
                <input
                  className="contact-input"
                  autoComplete="off"
                  name="Last Name"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <label>Last Name</label>
                <FontAwesomeIcon className="icons" icon={faAddressCard} />
              </div> */}
              <div className="input-wrap w-100">
                <input
                  className="contact-input"
                  autoComplete="off"
                  name="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label>Email</label>
                <FontAwesomeIcon className="icons" icon={faEnvelope} />
              </div>

              <div className="input-wrap w-100">
                <input
                  name="Subject"
                  autoComplete="off"
                  className="contact-input"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
                <label>Subject</label>
                <FontAwesomeIcon className="icons" icon={faPaperPlane} />
              </div>

              <div className="input-wrap w-100">
                <textarea
                  name="Message"
                  autoComplete="off"
                  className="contact-input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
                <label>Message</label>
                <FontAwesomeIcon className="icons" icon={faInbox} />
              </div>

              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
